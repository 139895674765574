@if (this.showLabel) {
  <span class="p-float-label">
    <ng-container *ngTemplateOutlet="calendarInput"/>

    <label [htmlFor]="this.uid">{{ label ?? '' }}</label>
  </span>
} @else {
  <ng-container *ngTemplateOutlet="calendarInput"/>
}

<ng-template #calendarInput>
  <p-calendar [showTime]="true"
              [style]="{'width': '100%'}"
              [showButtonBar]="true"
              [firstDayOfWeek]="1"
              [ngModel]="this.dateValue"
              (ngModelChange)="this.onValueChange($event)"
              [inputId]="this.uid"
              appendTo="body"
              dateFormat="dd-mm-yy"
              hourFormat="24"/>
</ng-template>
